import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
function useWidth() { const theme = useTheme(); const greaterThanMid = useMediaQuery(theme.breakpoints.up("md")); const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md")); const xsToSmall = useMediaQuery(theme.breakpoints.between("xs", "sm")); const lessThanXS = useMediaQuery(theme.breakpoints.down("xs")); if (greaterThanMid) {
    return "lg";
}
else if (smallToMid) {
    return "md";
}
else if (xsToSmall) {
    return "sm";
}
else if (lessThanXS) {
    return "xs";
}
else {
    return "xl";
} }
export default useWidth;
